import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player/vimeo';

import Box from '@/atoms/Box';
import Image from '@/components/Image';

import { media } from '@/styles/utils';

const MEDIA_HEIGHT = 360;

const Player = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const Item = styled(Box)`
  position: relative;
  flex: 0 0 auto;

  ${props =>
    props.$type === 'vimeoEmbed' &&
    `
    padding-bottom: 56.25%;
  `}

  ${media.md`
    display: inline-block;
    width: auto;
    height: ${MEDIA_HEIGHT}px;

    ${props =>
      props.$type === 'vimeoEmbed' &&
      `
      padding-bottom: 0;
      width: ${MEDIA_HEIGHT * props.$aspectRatio}px;
    `}

    ${props =>
      props.$type === 'imageWithAlt' &&
      `
      width: ${MEDIA_HEIGHT * props.$aspectRatio}px;
    `}
  `}
`;

export default ({ items = [], ...props }) => (
  <Box $display={['block', 'block', 'flex']} $flexWrap="wrap" {...props}>
    {items.map(({ key, type, ...itemProps }) => {
      const aspectRatio =
        type === 'imageWithAlt' && itemProps.asset
          ? itemProps.asset.fluid.aspectRatio
          : itemProps.aspectRatio || 1.777777;

      return (
        <Item
          key={key}
          $type={type}
          $aspectRatio={aspectRatio}
          mb={[1, 1, 1.5]}
          mr={[0, 0, 1.5]}
        >
          {type === 'vimeoEmbed' && (
            <Player
              url={`https://vimeo.com/${itemProps.vimeoId}`}
              width="100%"
              height="100%"
              controls
              config={{
                vimeo: {
                  playerOptions: {
                    color: 'e5e4e4',
                    byline: false,
                    portrait: false,
                    title: false,
                  },
                },
              }}
            />
          )}

          {type === 'imageWithAlt' && (
            <Image
              asset={itemProps.asset}
              alt={itemProps.alt}
              columnWidth={[1, 1, 1 / 3]}
            />
          )}
        </Item>
      );
    })}
  </Box>
);

import React from 'react';
import { graphql } from 'gatsby';

import Head from '@/components/Head';
import ProjectTitle from '@/components/ProjectTitle';
import MediaGrid from '@/components/MediaGrid';
import Grid from '@/atoms/Grid';
import RichText from '@/atoms/RichText';

export default ({
  data: {
    project: { title, date, meta, client, description, media },
  },
}) => {
  return (
    <>
      <Head title={`${client.title} – ${title}`} meta={meta} />

      <Grid.Container>
        <ProjectTitle
          as="h1"
          mb={[1, 1, 1.5]}
          title={title}
          date={date}
          client={client.title}
        />

        <RichText blocksRaw={description.textRaw} />

        <MediaGrid mt={[3, 3, 4]} items={media} />
      </Grid.Container>
    </>
  );
};

export const query = graphql`
  query($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      title
      date(formatString: "MM.YY")
      client {
        title
      }
      meta {
        ...metaData
      }
      description {
        textRaw: _rawText
      }
      media {
        ... on SanityVimeoEmbed {
          key: _key
          type: _type
          vimeoId
          aspectRatio
        }
        ... on SanityImageWithAlt {
          key: _key
          type: _type
          ...imageWithAltData
        }
      }
    }
  }
`;
